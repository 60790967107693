import Carousel from 'react-bootstrap/Carousel';
import './Carousel.sass';

function SimpleCarousel() {
  return (
    <Carousel controls={false} indicators={false}>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="https://server.someoneinteresting.com/api/images/blog/fc2e05df-a7db-4137-9be9-6d28fa44bbde.png"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="	https://server.someoneinteresting.com/api/images/blog/83c6910d-a0dc-405e-a854-c855f60f5770.png"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="	https://server.someoneinteresting.com/api/images/blog/e5676eef-bded-4119-88b3-533d21bb5a17.png"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default SimpleCarousel;