import './Home.sass';
import { BlogList } from '../../models/BlogList';
import { Link } from 'react-router-dom';
import SimpleCarousel from '../../components/carousel/Carousel';

interface blogList {
    blogList: BlogList[] | undefined
}

const Home = ({blogList}: blogList) => {

    let filtered: any = [];
    if (blogList) {       
        if (blogList.length > 4) {
            for (let i = 28; i < 32; i++) {
                filtered.push(blogList[i]);     
            }
        } else {
            blogList.forEach(element => {
                filtered.push(element); 
            })
        }
    } 

    return (
        <main className='home'>
            <div className="home__wrapper container">
                <h1 className="home__title">The Best Free Tour Guides In The World</h1>
                <h2>With Us You Can:</h2>
                <div className='home__possibilities-wrapper'>
                    <div className="home__possibilities">
                        <ul>
                            <li>Find an experienced prospective guide who can assist in increasing crop production and discovering the special significance and nooks and crannies of our destination</li>
                            <li>Find a guide that suits your interests and preferences for the most personalized and exciting trip</li>
                            <li>Book your guide quickly and easily through our user-friendly platform, ensuring your trip is safe and secure</li>
                        </ul>
                    </div>
                    <SimpleCarousel></SimpleCarousel>
                </div>
                <div className="home__blog">
                    <h2>Our articles:</h2>
                    <div className="home__blog__wrapper">
                        {filtered.map((element: any) => (
                            <Link key={element._id} to={'/blog/' + element._id} className="blog__item">
                                <img src={"https://server.someoneinteresting.com/api/images/blog/" + element.img} alt="Country" />  
                                <div className="blog__type">{element.type}</div>
                                <div className="blog__content">
                                    <h3 className="blog__title">{element.title}</h3>
                                    <p className="blog__descr">{element.text.slice(0,230)}...</p>
                                    <span>{String(element.date).slice(0,10)}</span>
                                </div>            
                            </Link>
                        ))} 
                    </div>
                    <Link to="/blog" className='home__blog__btn' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>Read more</Link>                     
                </div>
            </div>
        </main>
    )
}

export default Home;