import { Link } from 'react-router-dom';
import './Header.sass';
import { Context } from '../..';
import { useContext} from 'react';
import icon from '../../icons/icon.svg';
import blueIcon from '../../icons/blueIcon.svg';
import greenIcon from '../../icons/greenIcon.svg';
import purpleIcon from '../../icons/purpleIcon.svg';

interface headerProps {
    isAuth: boolean
}

const Header = ({isAuth}: headerProps) => {
    const {store} = useContext(Context);

    const toggleModal = () => {   
        document.querySelector('.header__wrapper')?.classList.toggle('header__wrapper-none');
    }
    
    return (
        <header className='header'>
            <div className="header__wrapper header__wrapper-none container">
                <i className="fa-solid fa-xmark header__close" onClick={toggleModal}></i>
                <Link to="/" className="header__logo-link" onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'}); toggleModal()}}>
                    <img className='header__logo' src={purpleIcon} alt="Logo" />
                    LocalTravelHub              
                </Link>
                <nav className="header__nav">
                    <Link to="/blog" onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'}); toggleModal()}}>Blog</Link>
                    <Link to="/guides" onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'}); toggleModal()}}>Guides</Link>
                </nav>
                {!isAuth ? (
                    <div className="header__login">
                        <i className="fa-solid fa-user"></i>
                        <span>Log In</span>
                        <i className="fa-solid fa-angle-down arrow-down"></i>
                        <div className="header__dropdown">
                            <Link to="/login-traveler" onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'}); toggleModal()}}>Traveler login</Link>
                            <Link to="/login-guide" onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'}); toggleModal()}}>Guide login</Link>
                        </div>
                    </div>
                    ) : (
                        <div className="header__logout">
                            {store.user.type === 'guide' ? (
                                <Link to='/guides/profile' onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'}); toggleModal()}}>{store.user.firstName}</Link>
                            ) : (
                                store.user.type !== 'admin' && <span>{store.user.firstName}</span>
                            )}
                            <Link to='/' onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'}); toggleModal()}}>
                                <i onClick={() => store.logout()} className="fa-solid fa-right-from-bracket logout"></i>
                            </Link>
                        </div>
                    )
                }               
            </div>
            <i className="fa-solid fa-bars header__hamburger" onClick={toggleModal}></i>
            <Link to="/" className="header__logo-mobile" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                    <img src={purpleIcon} alt="Logo" />
                    LocalTravelHub              
            </Link>
        </header>
    )
}

export default Header;