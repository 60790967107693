import './PrivacyPolicy.sass'

const PrivacyPolicy = () => {
    return (
        <main className='privacyPolicy container'>
            <h3>Privacy Policy</h3>
            <p>
                <br/>
                Data privacy is important to LocalTravelHub and we are committed to respecting your privacy.<br/>
                This privacy policy (“Privacy Policy”) contains important information about how we collect and use your personal data and the choices you can make about your personal data.<br/>
                <br/>
                By accessing or using the LocalTravelHub marketplace platform accessible via the www.LocalTravelHub.com website (the “Site”), and/or by providing information to us in connection with our Site or services, you acknowledge that you have read and understood the terms and conditions of this Privacy Policy.<br/>
                <br/>
                <b>The controller of your personal data.</b><br/>
                <br/>
                <b>Personal data collected.</b><br/>
                <br/>
                We collect personal data from you through our interactions with you and when you share it with us through, or in connection with, but not limited to, the Site, our services and surveys. For example, you may share your personal data when you open an account on our Site, sign up to our newsletter, submit an inquiry, book a tour, file a complaint or publish a review.<br/>
                <br/>
                We may produce anonymous data from your personal data and other individuals’ personal data by making the data non-identifiable to any person. We may use that anonymous data for our business activity.<br/>
                <br/>
                Also, you may share personal data from a third party. For example, you may provide the name, email address and telephone number of a tour participant or tour service provider. In those cases, you are responsible for getting authorization from those third parties to share their personal data with us and to give us permission to collect and process their personal data in accordance with this Privacy Policy.<br/>
                <br/>
                You may find below a list of the ways in which we collect personal data from you and what we use that information for.<br/>
                <br/>
                <b>Where is your data stored?</b><br/>
                <br/>
                The personal data that we collect from you is stored in Canada.<br/>
                <br/>
                We employ administrative, technical, and physical security measures to prevent loss, manipulation and unauthorized access to your personal data. However, due to the nature of the online activity it is not possible to ensure that it will be fully secure or error-free. While we strive to maintain high privacy standards, we cannot guarantee the full security of your personal information. Please keep this in mind when disclosing any personal data to us.<br/>
                <br/>
                <b>Data retention.</b><br/>
                <br/>
                We will retain your personal data for as long as:<br/>
                - You maintain an account active on the Site;<br/>
                - Needed to provide services to you;<br/>
                - To maintain a record of your transactions for tax purposes;<br/>
                - Necessary for customer service purposes;<br/>
                - Necessary to comply with our legal obligations;<br/>
                - To enforce our agreements.<br/>
                <br/>
                <b>Sharing of personal data.</b><br/>
                <br/>
                Your personal data may be shared with third parties for purposes consistent with this Privacy Policy:<br/>
                <br/>
                a. With affiliated companies which may process the data in countries other than the country in which the data was originally collected;<br/>
                b. With other users of the Site, for the purpose of providing and/or using the services required by you. For example, when we share personal data among the travellers, the guides and/or the travel agents for the purpose of delivering a booking and coordinating the tour. We are not responsible for the privacy practices of the users of the Site. The terms of the privacy policies of such users apply to the disclosure of any personal data provided to them;<br/>
                c. With data service providers, such as cloud, hosting, server maintenance, and backup services. Data service providers will have to maintain the confidentiality of your personal data;<br/>
                d. With other service providers to edit, adapt and/or modify the content you uploaded to the Site;<br/>
                e. When we are required to do so by law, including disclosure and use of your personal data as necessary or appropriate to comply with applicable laws and administrative or legal processes, such as to respond to requests from government authorities;<br/>
                f. To exercise and/or defend our legal rights, including providing information to governments and/or law enforcement officials;<br/>
                g. To marketing service providers to analyze and execute advertising activities, such as newsletters.<br/>
                <br/>
                You hereby agree to hold LocalTravelHub harmless for any damages that may result should any personal information about You be released by any third parties, including but not limited to, any user of the Site.<br/>
                <br/>
                We never sell or exchange your personal data for marketing purposes to third parties.<br/>
                <br/>
                <b>Your data protection rights.</b><br/>
                <br/>
                In some countries, applicable law may provide you with the following rights:<br/>
                <br/>
                <i>Right to access</i>: You may request, at any time, information about your personal data stored by us. You can access most of your personal data by entering your account on our Site.<br/>
                <br/>
                <i>Right to portability</i>: You may request a copy of your personal data stored by us to be sent to you or to another party.<br/>
                <br/>
                <i>Right to rectification</i>: You may request to correct inaccurate or incomplete personal data about you and stored by us. If you have an account, you can edit and complete your personal data through your account on our Site.<br/>
                <br/>
                <i>Right to erasure</i>: You may contact us to request to delete all your personal data. Your personal data will be erased, except in the following situations:<br/>
                a. When necessary for the performance of the contract: like when you have a pending booking;<br/>
                b. When necessary for our legitimate business interests: like if you have an open case with Customer Service, if you have a debt with us or with a user of the Site, if you are related to any kind of claim regarding our services or the services provided by a user of the Site and for safety reasons;<br/>
                c. When necessary to comply with legal obligations: like tax information;<br/>
                d. Your reviews may not be erased from the Site, but we will anonymize it and erase any information that may link to you.<br/>
                <br/>
                <i>Withdraw Consent</i>: You may also withdraw your consent given to us to collect and process your personal data, at any time. In that case, we will no longer process your personal data, anonymize it or delete it, as appropriate.<br/>
                <br/>
                When we ask you to provide personal data, you can decide not to provide it. In some situations, when you decide not to provide personal data to us, we may be unable to provide our services to you. For example, we may be unable to connect you with another user of the Site if you do not provide us with your full name. Also, for security reasons, you may be unable to open a guide’s account on the Site if you do not provide us with your personal data for screening purposes.<br/>
                <br/>
                To exercise your rights please contact Customer Support at <u>support@LocalTravelHub.com</u>.<br/>
                <br/>
                <b>Newsletters and Marketing Campaigns</b><br/>
                <br/>
                We may use your personal data for marketing and promotional campaigns when you opt-in to receive newsletters and/or promotional emails when you create an account on the Site. You can ask us to stop sending you marketing messages at any time by editing your account preferences or by clicking the unsubscribe link in the footer of our emails.<br/>
                <br/>
                <b>Cookies.</b><br/>
                <br/>
                We use cookies and other automated tracking technologies to collect certain information from you, such as an IP address, location, page views, links clicked, br/owser type, device type, referring/exit URLs, operating system, language, and other usage information. Also, service providers like Google Analytics, Google Ads, Bing Ads, Facebook Ads, may use automated tracking technologies to tailor ads or deliver content when you are on our Site or on other sites. The information generated by the service providers will be stored by them, subject to their privacy policies and may be shared with us.<br/>
                <br/>
                We use this information to:<br/>
                <br/>
                a. Improve your br/owsing experience, for example, to keep you signed in, to remember items in your shopping cart, and to save your preferences for future visits;<br/>
                b. Determine which areas and features are most popular and which may need to be improved;<br/>
                c. Keep track of advertisements, search engine results, and your behavior on our Site.<br/>
                <br/>
                You can set your br/owser to block these cookies. But consider that you might not be able to take advantage of all the Site’s features and your user experience might be limited. Also, our platform does not currently respond to a “Do Not Track” signal from your br/owser.<br/>
                <br/>
                <b>Links to third party websites.</b><br/>
                <br/>
                The Site contains links to sites and those sites do not follow this Privacy Policy. If you visit other sites by clicking on a link from our Site, we recommend that you read their privacy policies before using their information and/or services. Linked sites are not under our control nor we approve, or endorse any information, services or products available through any linked site. We accept no responsibility or liability for the content of these other sites or their privacy practices.<br/>
                <br/>
                <b>Changes to this Privacy Policy.</b><br/>
                <br/>
                Please review this Privacy Policy periodically. We may update or amend this Privacy Policy from time to time, at our sole discretion and without prior notice to you, by posting the updated version on the Site, or, if you have provided us with your email address, by sending you an email with the updated version with at least 30 days before the date it becomes effective. If you continue using the Site and/or our services, then you are deemed to have accepted the amended Privacy Policy.<br/>
                <br/>
                <b>You may contact us.</b><br/>
                <br/>
                To exercise any rights related to your personal data and/or to request any information about our Privacy Policy, please contact Customer Service at <u>support@LocalTravelHub.com</u>.<br/>
                <br/>
                <b>Traveler's Account</b><br/>
                <i>When do we generally collect your personal data?</i><br/>
                - Register on the Site;<br/>
                - Update your personal data;<br/>
                - Use our message system;<br/>
                - Book a tour;<br/>
                - Post a review;<br/>
                - Respond to surveys;<br/>
                - Communicate with Customer Service through messages or by phone.<br/>
                <i>What type of personal data do we usually process?</i><br/>
                - Contact details, such as full name, nationality and email address;<br/>
                - All personal data sent through the Site’s message system;<br/>
                - Your preferences;<br/>
                - Bookings related information;<br/>
                - Optional: address, telephone numbers and medical/dietary concerns.<br/>
                <i>Why do we use your personal data?</i><br/>
                - Identify you and enable the opening of your account;<br/>
                - Communicate with you in regards to any booking inquiry;<br/>
                - Contact from Customer Service;<br/>
                - Share it with the guides that you decide to get in contact with: we may share your contact details, messages through the system and through the phone, number of bookings, reviews;<br/>
                - Process payments;<br/>
                - Publish a tour review.<br/>
                <i>What are the legal grounds to process your personal data?</i><br/>
                - To maintain your account: is based on your consent when you register;<br/>
                - To provide you with products and services: is based on the performance of the contract;<br/>
                - To provide you with relevant product information: is based on our legitimate interest;<br/>
                - We may also be subject to a legal obligation to process your personal data to comply with tax regulations.<br/>
                <br/>
                <b>Guide's Account</b><br/>
                <i>When do we generally collect your personal data?</i><br/>
                - Onboarding process;<br/>
                - Update your personal data;<br/>
                - Use our message system;<br/>
                - Respond to surveys;<br/>
                - Communicate with Customer Service.<br/>
                <i>What type of personal data do we usually process?</i><br/>
                - Your profile's biography;<br/>
                - Your photos and videos;<br/>
                - All personal data sent through our message system;<br/>
                - Banking information;<br/>
                - Payment history;<br/>
                - Tax information;<br/>
                - Information from screening services;<br/>
                - Optional: skype name.<br/>
                <i>Why do we use your personal data?</i><br/>
                - Identify you and enable the opening of your account;<br/>
                - List your tours on the Site;<br/>
                - Communicate any news, activities, surveys related to our services;<br/>
                - Communicate with you in regards to any booking;<br/>
                - Contact from Customer Service;<br/>
                - Share it with the travelers and travel agents that you decide to get in contact with;<br/>
                - Publish it on the Site;<br/>
                - Send remittances to you;<br/>
                - Conduct ongoing screening services to guides.<br/>
                <i>What are the legal grounds to process your personal data?</i><br/>
                - To maintain your account: is based on your consent when you register;<br/>
                - To provide you with products and services: is based on the performance of the contract;<br/>
                - To provide you with relevant product information: is based on our legitimate interest;<br/>
                - We may also be subject to a legal obligation to process your personal data to comply with tax regulations.<br/>
                <br/>
                <b>Travel Agent's Account</b><br/>
                <i>When do we generally collect your personal data?</i><br/>
                - Register;<br/>
                - Use the Site;<br/>
                - Use our message system;<br/>
                - Respond to surveys;<br/>
                - Communicate with Customer Service.<br/>
                <i>What type of personal data do we usually process?</i><br/>
                - Agent's contact details, such as full name, email address, telephone numbers;<br/>
                - All personal data sent through our message system;<br/>
                - Banking information;<br/>
                - Payment information;<br/>
                - Tax information;<br/>
                - Optional: skype name.<br/>
                <i>Why do we use your personal data?</i><br/>
                - Identify you and enable the opening of your account;<br/>
                - Communicate with you in regards to any booking;<br/>
                - Contact from Customer Service;<br/>
                - Share it with the guide that you decide to get in contact with;<br/>
                - Send payments to you.<br/>
                <i>What are the legal grounds to process your personal data?</i><br/>
                - Take steps, at your request, prior to entering into a contract (Travel Agent Agreement).<br/>
                - If the affiliate account is opened, your personal data will be processed for the performance of the contract;<br/>
                - We may also be subject to a legal obligation to process your personal data to comply with tax regulations.<br/>
                <br/>
                <b>Affiliate's Account</b><br/>
                <i>When do we generally collect your personal data?</i><br/>
                - Register;<br/>
                - Update your personal data;<br/>
                - Communicate with Customer Service.<br/>
                <i>What type of personal data do we usually process?</i><br/>
                - Affiliate's full name, address, nationality, website, email address, telephone numbers;<br/>
                - Banking information;<br/>
                - Payment information;<br/>
                - Optional: skype name.<br/>
                <i>Why do we use your personal data?</i><br/>
                - Identify you and enable the opening of your account;<br/>
                - Communicate with you in regards to your services;<br/>
                - Communicate any news related to our affiliates program;<br/>
                - Send payments to you.<br/>
                <i>What are the legal grounds to process your personal data?</i><br/>
                - Take steps, at your request, prior to entering into a contract (Affiliate Agreement).<br/>
                - If the affiliate account is opened, your personal data will be processed for the performance of the contract;<br/>
                - We may also be subject to a legal obligation to process your personal data to comply with tax regulations.<br/>
                <br/>
                <b>Guide Applicants</b><br/>
                <i>When do we generally collect your personal data?</i><br/>
                - Apply to open a guide’s account;<br/>
                - Recruiting interviews;<br/>
                - Contact references.<br/>
                <i>What type of personal data do we usually process?</i><br/>
                - Contact details, such as full name, date of birth, nationality, address, telephone numbers, email address, information about your guiding activity;<br/>
                - Experience as a guide;<br/>
                - References;<br/>
                - Information from screening services.<br/>
                <i>Why do we use your personal data?</i><br/>
                - Communicate with you in regards to your application;<br/>
                - Conduct a screening process;<br/>
                - Request references;<br/>
                - Identify you and analyze the opening of your account.<br/>
                <i>What are the legal grounds to process your personal data?</i><br/>
                - Take steps, at your request, prior to entering into a contract (Independent Tour Guide Agreement).<br/>
                <br/>
                <b>Buy a Gift Card</b><br/>
                <i>When do we generally collect your personal data?</i><br/>
                - Follow the steps to buy a gift card.<br/>
                <i>What type of personal data do we usually process?</i><br/>
                - Contact details, such as full name, email address, telephone numbers.<br/>
                <i>Why do we use your personal data?</i><br/>
                - Identify you and enable the issuance of the gift card;<br/>
                - To inform the recipient about the identity of the sender, if applicable.<br/>
                <i>What are the legal grounds to process your personal data?</i><br/>
                - Performance of the contract.<br/>
                <br/>
                <b>Newsletters</b><br/>
                <i>When do we generally collect your personal data?</i><br/>
                - Opt-in during sign up;<br/>
                - Click the opt-in box in your account.<br/>
                <i>What type of personal data do we usually process?</i><br/>
                - Full name;<br/>
                - Email address.<br/>
                <i>Why do we use your personal data?</i><br/>
                - Required to send you a personalized email.<br/>
                <i>What are the legal grounds to process your personal data?</i><br/>
                - Your consent to receive newsletters. You can withdraw your consent by editing the settings in your account or by clicking unsubscribe in the newsletter message.<br/>
                <br/>
                </p>
        </main>
    )
}

export default PrivacyPolicy;